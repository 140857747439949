<template>
	<w-dialog
		v-model="showDialog"
		:max-width="$vuetify.breakpoint.mdAndUp ? '40vw' : '90vw'"
		:title="$t('documents.rename', { type: itemType })"
		@ready="onDialogReady"
		@close="close"
	>
		<v-form ref="form" @submit.prevent="save">
			<v-layout row>
				<v-text-field
					ref="nameInput"
					v-model.trim="name"
					color="primary"
					:label="$t('name')"
					maxlength="191"
					:rules="[rules.validName, rules.uniqueName]"
				/>
			</v-layout>
		</v-form>

		<template v-slot:actions>
			<v-spacer />
			<w-btn-save :disabled="!isValid || isUnchanged" :loading="isLoading" @click="save" />
		</template>
	</w-dialog>
</template>

<script>
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'
import { mapMutations } from "vuex";

export default {
	name: 'RenameDialog',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		},
		items: {
			default: () => [],
			required: false,
			type: Array
		}
	},
	data: function () {
		return {
			name: '',
			showDialog: true,
			isLoading: false,
			rules: {
				validName: value => {
					if (value.trim() === '') {
       					return this.$t('documents.empty_item_name')
    				}
					if (!(/^[^/\\|:?<>*"]+$/i).test(value)) {
						return this.$t('documents.bad_item_name')
					}

					return true
				},
				uniqueName: () => {
					if (this.isNameUnique) {
						return true
					}
					if (this.model.type === 'folder') {
						return this.$t('documents.rename_folder_same_name')
					}

					return this.$t('documents.rename_document_same_name')
				}
			}
		}
	},
	computed: {
		model: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		parentFolder: function () {
			return this.model ? this.model.parent : null
		},
		itemType: function () {
			return this.model.type === 'folder'
				? this.$t('documents.types.folder')
				: this.$t('documents.types.file')
		},
		isNameUnique: function () {
			if (this.model.is_folder) {
				return !this.parentFolder.folders.find(folder => folder.name === this.name && folder.id !== this.model.id)
			}

			const nameWithExtension = this.getValidDocumentName(this.model.name, this.name)
			if (!this.parentFolder) {
				return !this.items?.length || !this.items.find(doc => doc.name === nameWithExtension && doc.id !== this.model.id)
			}

			return !this.parentFolder.documents.find(doc => doc.name === nameWithExtension && doc.id !== this.model.id)
		},
		isValid: function () {
			return this.name.trim().length > 0 && this.isNameUnique
		},
		isUnchanged : function () {
			return this.model.name === this.name 
		},
		nodes: function () {
			return !Array.isArray(this.value) && this.value ? [this.value] : this.value
		},
	},
	created () {
		this.setPreventKeyboardKeys([
			this.keyboard.arrow.left,
			this.keyboard.arrow.right,
			this.keyboard.arrow.up,
			this.keyboard.arrow.down,
		])
		this.name = this.model.name
	},
	destroyed: function () {
		this.setPreventKeyboardKeys([])
		this.close()
	},
	methods: {
		...mapMutations('documents/preview', ['setPreventKeyboardKeys']),
		onDialogReady () {
			this.$nextTick(() => {
				this.$refs.nameInput.focus();
			})
		},
		close: function () {
			this.showDialog = false
			this.$emit('close')
		},
		save: async function () {
			if (! this.isValid || this.isUnchanged || this.isLoading) {
				return;
			}

			this.isLoading = true;
			const newName = this.model.is_folder ? this.name : this.getValidDocumentName(this.model.name, this.name)
			const updateAction = this.model.is_folder ? this.service.updateFolder : this.service.updateFolderDocument
			const folderId = this.parentFolder ? this.parentFolder.id : this.model.folder_id
			const vendorId = this.vendorId ? this.vendorId : this.model.vendor_id

			await updateAction(vendorId, folderId, this.model, null, { name: newName })

			this.model.name = newName
			const message = this.$tc(this.model.is_folder ? 'documents.folder_updated' : 'documents.document_updated', this.nodes.length)
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, message)
			this.close()
			this.isLoading = false;
		},
		getValidDocumentName: function (oldDocumentName, newDocumentName) {
			if (!oldDocumentName || !newDocumentName) {
				return newDocumentName
			}

			const oldExt = oldDocumentName.split('.').pop()
			const hasNewExt = newDocumentName.includes('.')

			return hasNewExt ? newDocumentName : `${newDocumentName}.${oldExt}`
		}
	}
}
</script>